<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card
          color="success"
          icon="mdi-file-upload"
          inline
          title="Importation des présences"
          class="px-5 py-3 mb-5"
          align="center"
          justify="center"
        >
          <v-card
            :class="{ 'grey lighten-2': dragover } + ' col-6  align-center'"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
          >
            <v-card-text>
              <v-row
                class="d-flex flex-column"
                dense
                align="center"
                justify="center"
              >
                <v-icon
                  :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                  size="60"
                  @click="$refs.fileInput.$refs.input.click()"
                >
                  mdi-cloud-upload
                </v-icon>
                <p>
                  {{ $t('importation.deposez_votre_fichier_ici_ou_cliquez_pour_le_selectionner') }}
                </p>
                <v-file-input
                  ref="fileInput"
                  v-model="fichier"
                  type="file"
                  style="display: none"
                  @change="onSelectFile()"
                />
              </v-row>
              <v-virtual-scroll
                v-if="uploadedFiles.length > 0"
                :items="uploadedFiles"
                height="80"
                item-height="50"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.name">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                        <span class="ml-3 text--secondary">
                          {{ item.size }} bytes</span>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        icon
                        @click.stop="removeFile(item.name)"
                      >
                        <v-icon> mdi-close-circle </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider />
                </template>
              </v-virtual-scroll>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                icon
                @click="closeDialog"
              >
                <v-icon id="close-button">
                  mdi-close
                </v-icon>
              </v-btn>

              <v-btn
                icon
                :disabled="!(uploadedFiles.length > 0) || loading"
                :loading="loading"
                @click="onImportFile()"
              >
                <v-icon id="upload-button">
                  mdi-upload
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-row
            v-if="returnedData"
            align="center"
            justify="center"
          >
            <v-col class="col-6  align-center">
              <v-expansion-panels
                class="mb-6"
              >
                <p class="text-h3 mb-2 font-weight-bold">
                  {{ $t('importation.resultats') }}
                </p>
                <v-expansion-panel
                  v-for="(messages, i) in returnedData"
                  :key="i"
                >
                  <v-expansion-panel-header
                    disable-icon-rotate
                  >
                    {{ i }}
                    <template
                      v-slot:actions
                    >
                      <v-icon
                        v-if="messages.filter(m => m.type.toUpperCase().includes('ERREUR')).length > 0"
                        color="error"
                      >
                        mdi-alert-circle
                      </v-icon>
                      <v-icon
                        v-else-if="messages.filter(m => m.type.toUpperCase().includes('AVERTISSEMENT')).length > 0"
                        color="orange darken-1"
                      >
                        mdi-alert
                      </v-icon>
                      <v-icon
                        v-else
                        color="teal"
                      >
                        mdi-check
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list-item
                      v-for="(message, j) in messages"
                      :key="j"
                      two-line
                    >
                      <v-list-item-content
                        align="left"
                        justify="left"
                      >
                        <v-list-item-title><strong style="text-transform:capitalize;"> {{ message.type }} </strong></v-list-item-title>
                        <v-list-item-subtitle>{{ message.message }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    name: 'Upload',
    data() {
      return {
        dragover: false,
        uploadedFiles: [],
        multiple: false,
        returnedData: null,
        fichier: undefined,
        loading: false,
      }
    },
    methods: {
      closeDialog() {
        this.uploadedFiles = []
        this.$emit('update:dialog', false)
      },

      removeFile(fileName) {
        const index = this.uploadedFiles.findIndex(
          file => file.name === fileName,
        )
        // If file is in uploaded files remove it
        if (index > -1) this.uploadedFiles.splice(index, 1)
        if (this.fichier) this.fichier = undefined
      },
      onDrop(e) {
        this.dragover = false
        // Check if there are already uploaded files
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          this.$dialog.error({
            text: i18n.t('importation.un_seul_fichier_peut_etre_telecharge_a_la_fois'),
            title: i18n.t('general.erreur'),
          })
        } else {
          e.dataTransfer.files.forEach(element =>
            this.uploadedFiles.push(element),
          )
        }
      },
      onSelectFile() {
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []
        console.log(this.fichier)
        if (this.fichier) {
          this.uploadedFiles.push(this.fichier)
        }
      },
      onImportFile() {
        // post the files to the server
        this.loading = true
        console.log('loading', this.loading)
        this.uploadedFiles.forEach(file => {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('creePar', this.$store.state.userInfo.prenom + ' ' + this.$store.state.userInfo.nom)
          restApiService.post('/api/webUser/inscriptionFacileExcel', formData).then(response => {
            console.log(response.data)
            if (response.data.status === 200) {
              this.uploadedFiles = []
              this.returnedData = response.data.entity
            } else {
              this.$dialog.error({
                text: i18n.t('importation.erreur_lors_de_limportation_des_donnees'),
                title: i18n.t('general.erreur'),
              })
              console.log('Error: ', response.data.entity)
              this.uploadedFiles = []
              if (this.fichier) this.fichier = undefined
            }
            this.loading = false
            console.log('loading', this.loading)
          }).catch(error => {
            this.$dialog.error({
              text: i18n.t('importation.erreur_lors_de_limportation_des_donnees'),
              title: i18n.t('general.erreur'),
            })
            console.log('Error: ', error)
            this.uploadedFiles = []
            if (this.fichier) this.fichier = undefined
            this.loading = false
            console.log('loading', this.loading)
          })
        })
      },
    },
  }
</script>
